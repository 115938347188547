@mixin text-font {
	font-family: 'font-book', helvetica, arial;
}
@mixin title-font {
	font-family: 'font-medium', helvetica, arial;
	font-weight: 700;
}

@mixin transition () {
	transition:all 0.2s ; 
	-moz-transition:all 0.2s; 
	-webkit-transition:all 0.2s;
}

@mixin borderRadius ($valor){
	-webkit-border-radius: $valor;
	-moz-border-radius: $valor;
	border-radius: $valor;
}

@mixin shadow (){
	-webkit-box-shadow: 0px 0px 20px 10px rgba(50, 50, 50, 0.20);
	-moz-box-shadow:    0px 0px 20px 10px rgba(50, 50, 50, 0.20);
	box-shadow:         0px 0px 20px 10px rgba(50, 50, 50, 0.20);
}

@mixin blur {
	filter: blur(3px);
  	-webkit-filter: blur(3px);
}