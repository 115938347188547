.anim-fade {
  animation: fade 1s 0.1s forwards;
}
.warning {
  color: $warning;
}
.error {
  color: $error-color;
}
.pending {
  color: $red;
}
.success {
  color: $success-color;
}

.simple-button {
  height: 3.5em;
  margin-left: 0.7em;
  padding: 0 1.5em;
  color: #fff;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  border-style: none;
  border-radius: 5px;
  div{
    height: 1em;
    width: 2em;
    display: inline-block;
  }
  i{
    margin-right: 5px;
    
  }
  img{
    height: 1.8em;
    margin-right: 5px;
  }
}

.confirm-button {
  background-color: $primary-500;
}
.cancel-button {
  background-color: gray;
}
@keyframes fade {
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@mixin icon {
  color: white;
  margin: 0 10px;
  width: 2em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 1em;
}
// button bar in picture upload
.img-btn-bar {
  .icon-red {
    background-color: $red;
    @include icon();
    cursor: default;
  }
  .icon-green {
    background-color: $primary-500;
    @include icon();
    cursor: default;
  }
}

/** DASHBOARD score box */
.table-responsive {
  overflow: visible;
}
.game-action,.game-action:visited{
  background: $primary-500;
  padding: 8px;
  border-radius: 5px;
  color: white;
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
  text-align: center;
}
.game-action:hover{
  background: lighten($color: $primary-500, $amount: 5%);
  color: white;
  cursor: pointer; 
}
.player-box,
.score-box {
  display: table-cell;
  vertical-align: top;
}
.score-box > div[class="score-line"]:first-child {
  padding-top: 5px;
}
.set-score-box {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.player-line {
  height: 45px;
}
.player {
  display: table;
  float: left;
  .name {
    display: table-cell;
    vertical-align: middle;
    padding-left: 5px;
    span {
      display: block;
      white-space: nowrap;
    }
    span.selected {
      color: $confirmColor;
      font-weight: bold;
    }
  }
}
.player.loser {
  .name {
    color: $red;
    font-weight: bold;
  }
}
.player.winner {
  .name {
    color: $green;
    font-weight: bold;
  }
}
.profile-pic {
  display: table-cell;
  border: #fff;
  z-index: 10;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-position: 50% 50% !important;
}
.profile-pic-big {
  width: 210px;
  margin: 0 auto;
  position: relative;
  a.edit-profile-pic {
    display: inline-block;
    position: absolute;
    width: 53px;
    height: 53px;
    background: $grey-900;
    right: -15px;
    bottom: 30px;
    color: #7f7f7f;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 1.5em;
    padding: 15px;
  }
  img {
    max-width: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
}
.set-count-line {
  text-align: right;
  white-space: nowrap;
  .count {
    margin: -20px 0 0 45px;
  }
  .set {
    display: inline-block;
    margin: 0 30px 0 0;
    font-size: 0.7em;
    color: #d2d2d2;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    padding: 3px;
  }
  .set.done {
    margin: 0px;
    a {
      padding: 5px;
      font-size: 1.3em;
      background-color: $primary-500;

      color: #fff;
      vertical-align: middle;
      border-radius: 20%;
      border-color: darken($color: $primary-500, $amount: 10%);
      border-style: solid;
      border-width: 1px;
    }
  }
  .line {
    margin: 10px 0px;
    width: 100%;
    height: 1px;
    background-color: #d2d2d2;
  }
}

.score-line {
  min-width: 296px;
  height: 45px;
  text-align: right;
  white-space: nowrap;
  position: relative;

  .set {
    padding-right: 15px;
    img {
      width: 25px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .set-score-input {
    @extend .set-circle;
    background: yellow;
    border: solid;
    border-color: black;
    border-width: 2px;
    color: $negro;
  }
  .set-score-input.error {
    @extend .set-circle;
    background: yellow;
    border: solid;
    border-color: $error-color;
    border-width: 2px;
    color: $negro;
  }
  .possibles {
    position: absolute;
    z-index: 10;
    background-color: $error-color;
    color: white;
    top: -20%;
    font-size: 0.8em;
    padding: 0.1em;
    margin-left: 0.5em;
  }
  .set-score-input.tb {
    @extend .set-circle;
    background-color: #4990e2;
    color: #fff;
    border: solid;
    border-color: black;
    border-width: 2px;
  }
  .set-score-input.tb.error {
    @extend .set-score-input.tb;
    border-color: $error-color;
  }
  .select-score-wrapper {
    position: relative;
    z-index: 100;
    -webkit-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
  .select-score {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    display: inline-block;
    height: 1.5em;
    line-height: 1.5em;
    box-sizing: content-box;
    padding: 0.3em 1.5em;
    overflow: hidden;
    border-right-color: white;
    border-right-width: 1px;
    border-right-style: solid;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    background: #0199d9;
  }
}

.box-match {
  .player {
    // float: left;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 2%;
    display: table;
    img {
      width: 45px;
      float: left;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
}
.no-data {
  background-color: #d2d2d2;
  color: black;
  padding: 2em 10px;
  margin: auto;
  width: 100%;
  text-align: center;
  font-size: 2em;
  font-weight: 400;
  p {
    padding-top: 1em;
    font-size: 0.5em;
  }
}
/** DASHBOARD END */
.header-ext {
  width: 100%;
  text-align: center;
  height: 70px;
  overflow: visible;
  @media (max-width: 768px) {
    height: 50px;
    text-align: left;
  }
  .logoExt {
    padding: 15px 0;
    width: 210px;
    display: inline-block;
    @media (max-width: 768px) {
      width: 170px;
      padding: 10px 0;
      margin-left: 15px;
    }
  }
  .col-right-header {
    position: absolute;
    top: 50%;
    margin-top: -19px;
    right: 15px;
    .buttonsClubs {
      display: none;
      @media (min-width: 992px) {
        display: block !important;
      }
    }
    .lang {
      .dropdown-toggle {
        text-transform: uppercase;
        font-size: 13px;
        height: 25px;
        line-height: 25px;
        color: $grey-400;
        background-color: $grey-050;
        padding: 0 10px;
        border-radius: 5px;
        display: inline-block;
        &:hover {
          background-color: $grey-100;
        }
        img {
          margin-left: 1px;
          width: 13px;
        }
      }
      .dropdown-menu {
        //display: block;
        left: unset;
        right: 0;
        padding: 12px 0;
        font-size: 16px;
        margin: 2px 0 0;
        background-color: #fff;
        border: 0;
        min-width: 190px;
        > li > a {
          padding: 8px 25px;
        }
      }
    }
  }
}

.headerOnBoarding {
  margin-bottom: 30px;
  @media (min-width: 992px) {
    margin-bottom: 50px;
    background: none;
    text-align: left;
    border-bottom: 1px solid rgba(256,256,256,.2);
  }
  .logoExt {
    @media (min-width: 992px) {
      margin-left: 15px;
    }
  }
  .col-right-header {
    overflow: hidden;
    .buttonsClubs {
      display: none;
      @media (min-width: 992px) {
        display: block !important;
        float: left;
        margin-right: 20px;
      }
      .btn-gral {
        padding: 7px 18px;
        margin: 0 5px;
        font-size: 14px;
      }
      .empty {
        border-color: $white;
      }
    }
    .lang {
      float: left;
      @media (min-width: 992px) {
        margin-top: 6px;
      }
    }
  }
}

.headerTop {
  background-color: $primary-500;
  color: #fff;
  font-family: $f-demi;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  height: 28px;
  line-height: 28px;
  overflow: hidden;
  @media screen and (min-width: 768px){
    font-size: 13px;
  }
  .carousel-banner {
    width: 400px;
    margin: 0 auto;
    transform: translateX(20px);
    .slider {
      .slide {
        padding: 0;
        text-align: left;
        @media screen and (min-width: 768px){
          text-align: left;
        }
      }
    }
  }
}

/* DASHBOARD END */

/* GAME SUMMARY */

.gameCode {
  margin: 15px 0;
  color: $grey-600;
  font-size: 12px;
}

.statusCard {
  padding: 30px 25px;
  color: #fff;
  text-align: center;
  padding: 0px 0px 20px;
  @media (max-width: 768px) {
    //padding: 20px 0px 20px;
  }
  h1 {
    font-size: 38px;
    text-transform: none;
    line-height: 1.3;
    font-family: $f-demi;
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  a.game-action {
    display: inline-block;
    border-radius: 20px;
    width: auto;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 13px;
    i {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

.cardGS {
    width: 100%;
    background-color: $primary-500;
    padding: 30px 60px 30px 30px;
    color: #fff;
    border-left: 5px solid $primary-400;
    margin-bottom: 1px;
    @include transition();
    //border-bottom-color: lighten($color: $summaryGray, $amount: 5%);
    @media (max-width: 768px) {
      padding: 30px 20px;
    }
    &.cardWarning {
      border-color: #F5A623;
    }
}


.game-summary {
  max-width: 850px;
  margin-bottom: 50px;
  margin-top: 80px;
  @media (min-width: 992px) {
    margin-top: 60px;
  }
  .game-summary-field-paint {
    background-color: $summaryGreen;
    padding: 1em;
    color: white;
  }
  .game-summary-field {
    position: relative;
    .field-text {
      position: relative;
      overflow: hidden;
      .field-icon {
        float: left;
        width: 45px;
        i {
          font-size: 1.5em;
        }
        
      }
      .field-header,
      .field-header-text {
        font-family: $f-demi;
        text-transform: uppercase;
        padding-bottom: 5px;
        padding-left: 60px;
        font-size: 13px;
        @media (max-width: 768px) {
          padding-left: 45px;
        }
        span {
          display: block;
          margin-top: 5px;
        }
      }
      .field-header-text {
        text-align: center;
        text-transform: none;
      }
      .field-content {
        padding-left: 60px;
        font-size: 16px;
        margin-top: 10px;
        @media (max-width: 768px) {
          padding-left: 45px;
        }
        
        .item-logistic {
          overflow: hidden;
          img {
            float: left;
            margin-right: 10px;
          }
        }
        .item-logistic-right {
          overflow: hidden;
          text-align: right;
          img {
            float: left;
            margin-right: 10px;
          }
        }
        .item-note-header {
          overflow: hidden;
          font-size: 0.8em;
          padding: 10px 0px;
          img {
            float: left;
            margin-right: 10px;
          }
        }
        .item-note-my, .item-note-item{
          overflow: hidden;
          font-size: 0.8em;
          padding: 10px 0px;
        }
        .item-note-item{
          text-align: left;
          img {
            float: left;
            margin-right: 10px;
          }
        }
        .item-note-my{
          text-align: right;
          img {
            float: right;
            margin-left: 10px;
          }
        }
        .reply-note {
          margin-left: 30px;
          margin-bottom: 20px;
          span {
            color: #7ac7c5;
            font-size: 14px;
            text-transform: uppercase;
          } 
        }

        i {
          margin-right: 0.5em;
          color: #fff;
          padding: 3px;
          font-size: 0.9em;
          border-radius: 50%;
          background-color: $summaryGreen;
        }
        textarea {          
          border: 1px solid #3a8c87;
          background: #06736c;
          padding: 20px;
          border-radius: 5px;
          // El color del placeholder que ya fue completado
          color: $primary-300 !important;
          // El color del placeholder focus (cuando se escribe)
          &:focus, &:focus::-webkit-input-placeholder, &:focus::-moz-placeholder, &:focus::-moz-placeholder, &:focus::-ms-input-placeholder, &:focus::-ms-input-placeholder {
            color: #fff !important;
          }
          // El color del placeholder sin ser completado
          &::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
            color: $primary-300 !important;
          }
          
        }
        textarea::placeholder{
          color: $primary-300;
        }
        .typeSpan {
          margin-bottom: 5px;
          span {
            @include label();
          }
        }
        
      }
      a {
        color: white;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    .field-action {
      // display: table-cell;
      // vertical-align: middle;
      // text-align: right;
      position: absolute;
      right: 5px;
      top: 0;
      i {
        cursor: pointer;
      }
    }
  }


  
}


.game-summary-detail {
  //background-color:lighten($color: black, $amount: 15%) ;
  margin-left: 60px;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-left: 45px;
  }
}

.game-summary-button, .file-content-button{
  padding: 3em 0;
  button {
    padding: 1em 4em;
    margin-left: 1em;
    margin-bottom: 1em;
    color: #fff;
    font-weight: bold;
    //text-transform: uppercase;
    border-style: none;
    border-radius: 30px;

  }
  button.confirm {
    background-color: $primary-500;
  }
  button.confirm:hover {
    background-color: lighten($color: $primary-500, $amount: 5%);
  }
  button.confirm:active {
    background-color: lighten($color: $primary-500, $amount: 10%);
  }
  button:disabled:hover,
  button[disabled] {
    background-color: #2c303b;
    color: lighten($color: #2c303b, $amount: 10%);
  }
  button.decline {
    background-color: $error-color;
  }
  button.decline:active {
    background-color: lighten($color: $error-color, $amount: 10%);
  }
  button.decline:hover {
    background-color: lighten($color: $error-color, $amount: 5%);
  }
}
.file-content-button{
  text-align: center;
  padding: 0px;
  button.confirm {
    background-color: lighten($color: $primary-500, $amount: 10%);
  }
}
.calendar-day,
.location-item {
  display: table;
  padding: 2em;
  border-style: solid;
  border-color: $primary-400;
  border-radius: 13px;
  border-width: 2px;
  padding: 2em;
  width: 100%;
  margin-bottom: 1em;
  background-color: $primary-400;
  @include transition();
  &.selected {
    background-color: $grey-900;
    border-color: $primary-200;
    @include transition();
  }
  &:hover {
    border-color: $primary-300;
    @include transition();
  }
  .location {
    cursor: pointer;
    display: table-cell;
  }
  .name {
    color: white;
    margin-bottom: 0.3em;
  }
  .name.selected {
    font-weight: bold;
  }
  .address {
    color: lighten($color: #329996, $amount: 20%);
  }
  .checkmark {
    display: none;
    text-align: right;
  }
  .checkmark.selected {
    display: table-cell;
    vertical-align: middle;
    color: white;
  }
  .subtitles {
    color: #7ac7c5;
  }
}
.location-item:hover {
  .checkmark {
    display: table-cell;
    vertical-align: middle;
    color: white;
  }
}
.calendar-day {
  .hour,
  .half-hour {
    color: white;
    display: inline-block;
    font-size: 14px;
    width: 40px;
    height: 40px;
    background-color: #3ba9aa;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 28px;
    vertical-align: top;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .half-hour {
    font-size: 11px;
    border-left: 1px solid lighten($color: $summaryGreen, $amount: 5%);
    margin-right: 1px;
  }
  .half-hour-selected {
    background-color: $secondary-2;
  }
  .half-hour-selected.first-selected {
    background-color: $secondary-2;
    border-left: solid;
    border-left-color:#035d57;
  }
  .hour.inactive, .half-hour.inactive {
    background-color: darken($color: $summaryGreen, $amount: 5%);
    background-color: $primary-500;
    color: #17968f;
  }  
  .selected-range {
    margin: -8px 0 15px -4px;
    min-height: 28px;
    .selected-hour {
      @include label();
    }
  }
}
.player-item{
  width: 100%;
  color: white;
  margin: 8px 0;
  a {
    display: block;
    cursor: pointer;
    padding: 5px 10px;
    overflow: hidden;
    background: $primary-400;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid;
    border-color: $primary-400;
    color: #fff;
    &:hover {
      border-color: $primary-300;
      @include transition();
    }
  }
  a.white {
    display: inline-block;
  }
  .profile-pic  {
    display: inline-block;
    float: left;
    width: 55px;
    height: 55px;
  }
  .name{
    //display: table-cell;
    //vertical-align: middle;
    padding-left: 1em;
    float: left;
    height: 55px;
    line-height: 55px;
  }
}



/** CUSTOMIZE EVERGREEN */
.segment-controll {
  background-color: $summaryGreen;
  div[data-active="true"] > label {
    color: #fff;
  }
}
/** EVERGREEN end */
$hoverColor: darken(
  $color: #fff,
  $amount: 10%
);
div.menu {
  div.item:hover:not(.selected) {
    background-color: $hoverColor;
  }
  .item {
    padding-left: 2em;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    cursor: pointer;
  }
  .item.selected {
    background-color: $summaryGreen;
    color: #fff;
  }
  .title {
    padding-left: 2em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    text-transform: uppercase;
  }
}

.day-wrapper {
  display: inline-block;
  vertical-align: top;
  margin-left: 2em;
  -webkit-box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.22);
  margin-bottom: 25px;
  .day {
    padding: 0.5em 1.5em 1em 1.5em;
    background-color: #eee;
    .weekday {
      padding-bottom: 5px;
      font-weight: bold;
    }
  }
  .time {
    padding: 0.5em 0.5em 0.5em 1.5em;
    cursor: pointer;
  }
  .time.selected {
    background-color: $summaryGreen;
    color: #fff;
  }
  .time:hover {
    background-color: $hoverColor;
  }
}

.player.partner {
  div.profile-pic {
    border-style: solid;
    border-color: $warning;
    border-width: 3px;
    cursor: pointer;
    float: left;
  }
  div.profile-pic:hover,
  div.profile-pic.selected {
    border-color: lighten($color: $confirmColor, $amount: 10%);
  }
}

// general disabled button
button:disabled:hover,
button[disabled] {
  background-color: $inactive;
  border-color: $inactive;
  color: white;
}
button.decline {
  background-color: $error-color;
}
button.decline:active {
  background-color: lighten($color: $error-color, $amount: 10%);
}
button.decline:hover {
  background-color: lighten($color: $error-color, $amount: 5%);
}

button.smallaction {
  padding: 0.7em 1em;
  margin-left: 1em;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-style: none;
  border-radius: 20px;
}
button.smallaction.confirm {
  background-color: $confirmColor;
}
button.smallaction.confirm:hover {
  background-color: lighten($color: $confirmColor, $amount: 5%);
}
button.smallaction.confirm:active {
  background-color: lighten($color: $confirmColor, $amount: 10%);
}
button.smallaction:disabled:hover,
button[disabled] {
  background-color: $inactive;
}
button.smallaction.decline {
  background-color: $error-color;
}
button.smallaction.decline:active {
  background-color: lighten($color: $error-color, $amount: 10%);
}
button.smallaction.decline:hover {
  background-color: lighten($color: $error-color, $amount: 5%);
}


// raw classess
// TEXT
.text-center {
  text-align: "center";
}

// font
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-5xl {
  font-size: 3rem;
}
.text-6xl {
  font-size: 4rem;
}

// opacity
.opacity-100 {
  opacity: 1;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-0 {
  opacity: 0;
}

.pd-top-1em {
  padding-top: 0.5em;
}
.pd-left-2em {
  padding-left: 2em;
}

.mg-between-1em {
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mg-right-1em {
  margin-right: 1em;
}
.right {
  float: right;
}
.white,
a.white:visited,
a.white:focus {
  color: white;
}
.uppercase {
  text-transform: uppercase;
}
.font-05em {
  font-size: 0.8em;
}
.wrapper-skills {
  background-color: #eeeeee;
  .skills-header {
    background-color: $grey-900;
    text-align: center;
    color: $primary-500;
    font-weight: bold;
    padding: 1em;
  }
  .skill-body-name,
  .skill-body-star {
    padding-top: 1em;
    height: 3.5em;
  }
  .skill-body-name {
    text-align: left;
    padding-left: 2em;
  }
  .skill-body-star {
    text-align: center;
  }
}
.circle-border{
  display: table-cell;
  width: 45px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  border-radius: 50%;
}

.deblock-user.table {
  background-color: #1D252C;
  color: #efefef;
  th, td {
    padding: 20px;
  }
  button{
    color: $secondary-1;
    border-radius: 5px;
  }
}
.select-all-button{
  background-color: #3ba9aa;
  color: white;
  border-style : solid;
  border-width: 1px;
  border-color: #3ba9aa;
  font-size: 0.7em;
  margin-right: 1em;

  &.selected{
    background-color:#5FBC57;
  }
}

.am-pm,.calendar-day .half-hour{
  font-size: 0.5em;
  vertical-align: super;
}
