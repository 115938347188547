// meter
.meter-gauge {
  background-color: gray;
  width: 100%;
  margin-top: 0.5em;
  height: 3px;
}

.meter-gauge > div {
  height: 3px;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.meter-gauge > div[data-value="0"] {
  background-color: $error-color;
  width: 20%;
}
.meter-gauge > div[data-value="1"] {
  background-color: #ff7f2a;
  width: 40%;
}
.meter-gauge > div[data-value="2"] {
  background-color: #ffcc00;
  width: 60%;
}
.meter-gauge > div[data-value="3"] {
  background-color: #54aced;
  width: 80%;
}
.meter-gauge > div[data-value="4"] {
  background-color: $success-color;
  width: 100%;
}
