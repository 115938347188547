
 @mixin campos() {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 20px);
	padding: 0.375rem 0.75rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	color: $grey-500;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $grey-300;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	// El color del placeholder que ya fue completado
	color: $grey-900 !important;

	// El color del placeholder focus (cuando se escribe)
	&:focus {
	}
	&:focus::-webkit-input-placeholder {
	}
	&:focus::-moz-placeholder {
	}
	&:focus::-moz-placeholder {
	}
	&:focus::-ms-input-placeholder {
	}

	// El color del placeholder sin ser completado
    &::placeholder {
        color: $grey-100;
    }
    &::-webkit-input-placeholder {
        color: $grey-100;
    }
    &::-moz-placeholder {
        color: $grey-100;
    }
    &:-ms-input-placeholder  {
		color: $grey-100;
	}

}

.form-group {
  margin-bottom: 25px;
  .showPass {
      font-size: 13px;
      float: right;
      color: $grey-300;
      font-family: $f-demi;
      cursor: pointer;
      img {
      width: 21px;
      }
      &:hover {
        color: $grey-100;;
      }
  }
  p.help-block {
      font-size: 14px;
  }
}

// Mixin

@mixin selects {
	appearance:none;
	-webkit-appearance: none;
	-moz-appearance:none;
	background-image: url(../images/icon-despliega.png);
	background-position:right 10px center;
	background-repeat:no-repeat;
}

@mixin label () {
    font-size: 14px;
    background-color: $grey-900;
    color: #fff;
    padding: 1px 9px;
    margin-right: 3px;
    margin-bottom: 5px;
    border-radius: 25px;
    display: inline-block;
    height: 23px;
    line-height: 23px;
  
}

label {
	font-weight: normal;
}

.form-control, input[type="submit"], input[type="email"], input[type="password"] {
	@include campos;
}

textarea {
	@include campos;
}


.form-group-2 {
    overflow: hidden;
    .form-group {
      width: 50%;
      float: left;
      &:first-child {
        padding-right: 2%;
      }
      select.signin-sel {
        //float: left;
        //margin-left: 10px;
        border-radius: 4px;
        font-size: 14px;
        padding: 10px 7px !important;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 0;
        background-image: url(../images/icon-despliega.png);
        background-position: right 10px center;
        background-repeat: no-repeat;
        width: 100%;
        color: #aaa;
        font-weight: normal;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        height: 50px;
        &::-webkit-input-placeholder {
          color: $negro;
          font-weight: normal;
        }
        &::-moz-placeholder {
          color: $negro;
          font-weight: normal;
        }
        &:-ms-input-placeholder {
          color: $negro;
          font-weight: normal;
        }
        &:-moz-placeholder {
          color: $negro;
          font-weight: normal;
        }
        @media (max-width: 768px) {
          float: none;
          margin-left: 0;
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }


  .box-radio-checkbox {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
  
    input[type="checkbox"], input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    /* Create a custom radio button */
    .radiobtn {
      position: relative;
      height: 18px;
      width: 18px;
      background: none;
      border: 2px solid #fff;
      @include borderRadius(4px);
      display: block;
      float: left;
      margin-right: 10px;
    }

    .radiobtn.circle {
      @include borderRadius(9px);
    }

    .circle:after {
      @include borderRadius(8px !important);
    }
  
    /* On mouse-over, add a grey background color */
    &:hover input ~ .radiobtn {
      background: none;
    }
  
    /* When the radio button is checked, add a color background */
    input:checked ~ .radiobtn {
      background: none;
    }
  
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .radiobtn:after {
      content: "";
      position: absolute;
      display: none;
    }
  
    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .radiobtn:after {
      display: block;
    }
  
    /* Style the indicator (dot/circle) */
    .radiobtn:after {
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
      @include borderRadius(2px);
      background: #fff;
    }

    


  }
    